"use client";
import useHandleSearchParams from "@/hooks/useHandleSearchParams";
import {
  useCurrentRefinements,
  useClearRefinements,
} from "react-instantsearch";
import {
  useIsResultTabView,
  useSearchResultCount,
} from "../SearchContextProvider";
import useRefinementExcludedAttributes from "../hooks/useRefinementExcludedAttributes";
import {
  PRODUCT_TO_RESULT_TYPE_MAP,
  PRODUCT_TYPES_TO_SHOW,
} from "../constants";

function SearchActiveRefinement() {
  const excludedAttributes = useRefinementExcludedAttributes();
  const { setIsResultTabView } = useIsResultTabView();
  const { setResultCount } = useSearchResultCount();
  const { items, refine } = useCurrentRefinements({
    excludedAttributes: excludedAttributes,
  });
  const { refine: clearAllRefinements } = useClearRefinements({
    excludedAttributes: excludedAttributes,
  });
  const { removeSearchParam, searchParams } = useHandleSearchParams();

  if (items.length === 0) return null;

  const handleClearAll = () => {
    setIsResultTabView(false);
    setResultCount(0);
    const queryValue = searchParams.q ? searchParams.q[0] : null;
    const newParams = new URLSearchParams();
    if (queryValue) {
      newParams.set("q", queryValue);
    }
    const newQuery = newParams.toString();
    const newUrl = `${window.location.pathname}${newQuery ? `?${newQuery}` : ""}`;
    window.history.pushState(null, "", newUrl);
    clearAllRefinements();
  };

  return (
    <div className="flex flex-wrap justify-start items-center gap-y-2">
      {items.map((item) =>
        item.refinements.map((refinement) => (
          <div
            key={refinement.label}
            className="border border-solid rounded mr-2 flex text-xs"
          >
            <div className="py-0.5 pl-2 flex items-center">
              {refinement.label}
            </div>
            <button
              className="h-auto pb-0.5 ml-1 px-2 hover:bg-black hover:text-white"
              onClick={() => {
                setResultCount(0);
                refine(refinement);
                if (
                  PRODUCT_TYPES_TO_SHOW.includes(refinement.value.toString())
                ) {
                  removeSearchParam(
                    "tab",
                    PRODUCT_TO_RESULT_TYPE_MAP[refinement.value.toString()],
                  );
                  setIsResultTabView(false);
                } else {
                  removeSearchParam(
                    refinement.attribute,
                    refinement.value.toString(),
                  );
                }
              }}
            >
              x
            </button>
          </div>
        )),
      )}
      <button
        className="ml-2 text-xs text-gray-700 underline hover:text-link-hover"
        onClick={handleClearAll}
      >
        Clear All
      </button>
    </div>
  );
}

export default SearchActiveRefinement;
