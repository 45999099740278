"use client";
import EventLink from "@/components/Link/EventLink/EventLink";

export type Props = {
  subtitle: string;
  description: string;
  links: { href: string; text: string }[];
};

export default function ContentColumnLinks({
  subtitle,
  description,
  links,
}: Props) {
  return (
    <div>
      <h4>{subtitle}</h4>
      <p>{description}</p>
      <ul>
        {links.map((link, index) => (
          <li key={index}>
            <p>
              <EventLink
                class_name="text-link underline"
                href={link.href}
                text={link.text}
                position={Number(index + 1).toString()}
                location="explore-offerings"
                html_id="product-link"
                element_type="entry"
              />
            </p>
          </li>
        ))}
      </ul>
    </div>
  );
}
