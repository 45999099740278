"use client";
import { useRefinementList } from "react-instantsearch";
import {
  FACET_SHOW_LIMIT,
  FACET_FETCH_LIMIT,
  FILTER_CATEGORIES_NAMES_MAP,
  LEARNING_TYPE_FILTER_SUBTITLE,
} from "../constants";
import useHandleSearchParams from "@/hooks/useHandleSearchParams";
import ModalButton from "@/components/Search/SearchFilterModal";
import SearchFiltersLoadingPlaceholder from "./FiltersLoading";
import { useSearchResultCount } from "../SearchContextProvider";
import getSortedRefinementItems from "../utils/getSortedRefinementItems";

function SearchFilterItem({ filterCategory }: { filterCategory: string }) {
  const { setSearchParam, removeSearchParam } = useHandleSearchParams();
  const { setResultCount } = useSearchResultCount();
  const { items, refine } = useRefinementList({
    attribute: filterCategory,
    sortBy: ["count:desc"],
    limit: FACET_FETCH_LIMIT,
    showMore: false,
    escapeFacetValues: true,
  });

  const totalItems = items.length;

  const handleRefinementClick = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setResultCount(0);
    if (event.target.checked) {
      setSearchParam(filterCategory, event.target.value);
    } else {
      removeSearchParam(filterCategory, event.target.value);
    }
    refine(event.target.value);
  };

  const renderItems = () => {
    if (!items.length) {
      return <SearchFiltersLoadingPlaceholder />;
    }
    return getSortedRefinementItems(items)
      .slice(0, FACET_SHOW_LIMIT)
      .map((item) => {
        return (
          <div key={item.label} className="py-1">
            <div className="py-1 flex justify-start items-start">
              <input
                type="checkbox"
                checked={item.isRefined}
                value={item.value}
                id={item.value}
                onChange={handleRefinementClick}
                className="border-2 mr-2 border-gray-700 min-w-4 h-5 w-5 cursor-pointer"
              />
              <div className="flex justify-start items-start flex-col">
                <label
                  htmlFor={item.value}
                  className="text-gray-700 flex items-center	mb-0 leading-5 "
                >
                  {item.label}
                </label>
                <label className="text-xs	text-gray-700 ">
                  {filterCategory === "learning_type" &&
                    LEARNING_TYPE_FILTER_SUBTITLE[
                      item.value as keyof typeof LEARNING_TYPE_FILTER_SUBTITLE
                    ]}
                </label>
              </div>
            </div>
          </div>
        );
      });
  };

  return (
    <div className="mb-4">
      <p className=" leading-6 text-md text-primary-500 font-bold mb-4">
        {FILTER_CATEGORIES_NAMES_MAP[filterCategory] || filterCategory}
      </p>
      <div className="flex flex-col gap-1">{renderItems()}</div>
      {totalItems > FACET_SHOW_LIMIT ? (
        <ModalButton selectedCategory={filterCategory}>
          <button
            className="text-gray-700 mt-2 text-md underline"
            aria-label="Show More"
          >
            Show More
          </button>
        </ModalButton>
      ) : null}
    </div>
  );
}

export default SearchFilterItem;
