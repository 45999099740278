import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter,
  DialogClose,
} from "@/components/Dialog";
import { FILTER_CATEGORIES } from "../constants";
import ModalAccordionItem from "./ModalAccordionItem";
import { useClearRefinements } from "react-instantsearch";
import useHandleSearchParams from "@/hooks/useHandleSearchParams";
import { ReactNode } from "react";
import useRefinementExcludedAttributes from "../hooks/useRefinementExcludedAttributes";
import * as Accordion from "@radix-ui/react-accordion";

const SearchFilterModal = ({
  children,
  triggerClassName,
  selectedCategory,
}: {
  children: ReactNode;
  triggerClassName?: string;
  selectedCategory?: string;
}) => {
  const { clearSearchParams } = useHandleSearchParams();
  const excludedAttributes = useRefinementExcludedAttributes();
  const { refine: clearAllRefinements } = useClearRefinements({
    excludedAttributes: excludedAttributes,
  });
  const handleClearAll = () => {
    clearAllRefinements();
    clearSearchParams();
  };

  return (
    <Dialog>
      <DialogTrigger className={triggerClassName}>{children}</DialogTrigger>
      <DialogContent className="p-0 gap-0">
        <DialogHeader className="p-4">
          <DialogTitle className="text-lg">All filters</DialogTitle>
        </DialogHeader>

        <Accordion.Root
          type="single"
          collapsible
          defaultValue={selectedCategory}
          className="px-0"
        >
          <div className="h-auto max-h-[70vh] overflow-y-scroll px-0">
            {FILTER_CATEGORIES.map((filterCategory) => (
              <ModalAccordionItem
                key={filterCategory}
                filterCategory={filterCategory}
              />
            ))}
          </div>
        </Accordion.Root>

        <DialogFooter className="flex flex-row flex-row-reverse h-24 p-6">
          <DialogClose>
            <button
              className="bg-transparent hover:bg-putty border-primary m-0 px-4 py-2 text-gray-dark"
              onClick={handleClearAll}
            >
              Clear all
            </button>
            <button className="bg-primary hover:bg-primary-300 border-primary m-0 px-4 py-2 text-white">
              Apply
            </button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default SearchFilterModal;
