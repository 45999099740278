"use client";

import React from "react";

import { ImagesListItemProps, LinkListProps } from "../types";
import FilterImageLink from "../FilterImageLink";

export default function Images({ heading = "", subjectMap }: LinkListProps) {
  if (subjectMap === undefined || subjectMap.length === 0) {
    return null;
  }
  const halfMap = Math.ceil(subjectMap.length / 2);
  return (
    <div className="fullwidth pb-8 mb-8 pl-0">
      {heading && (
        <h2 className="mt-0 mb-5 md:mb-6 text-3xl md:text-4xl">{heading}</h2>
      )}

      <div className="flex flex-wrap gap-3">
        {subjectMap.map((subject, index) => (
          <FilterImageLink key={index} {...subject} index={index} />
        ))}
      </div>
    </div>
  );
}
