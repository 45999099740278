"use client";
import { ElementClicked } from "@/hooks/eventing/types/redventures.usertracking.v3.ElementClicked";
import useElementEvents from "@/hooks/eventing/useElementEvents";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import { hyphenateForTagular } from "@/utils/tagular/helpers";
import {
  CommonVariants,
  DEFAULT_USE_IN_VIEW_OPTIONS,
  ElementType,
} from "@/constants/tagular/main";
import { TagularEventingRequiredProps } from "@/types/Tagular";

type FlexLinkProps = {
  label: string;
  url: string;
  index: number;
  sub_index: number;
} & TagularEventingRequiredProps;

export default function FlexLink({
  label,
  url,
  index,
  sub_index,
  location,
  html_id,
}: FlexLinkProps) {
  const tagularElement: ElementClicked["webElement"] = {
    elementType: ElementType.Link,
    htmlId: html_id,
    location: location,
    position:
      sub_index === undefined
        ? `${index + 1}`
        : `${index + 1}.${sub_index + 1}`,
    name: hyphenateForTagular(label),
    text: label,
  };

  const { elementClicked, elementViewed } = useElementEvents({
    webElement: tagularElement,
  });

  const handleClick = () => {
    elementClicked();
  };

  const { ref, inView } = useInView(DEFAULT_USE_IN_VIEW_OPTIONS);

  useEffect(() => {
    if (inView) {
      elementViewed();
    }
  }, [inView, elementViewed]);

  return (
    <a
      href={url}
      className="hover:underline capitalize"
      onClick={handleClick}
      ref={ref}
    >
      {label}
    </a>
  );
}
