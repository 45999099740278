"use client";

import {
  DEFAULT_USE_IN_VIEW_OPTIONS,
  ElementType,
} from "@/constants/tagular/main";
import { Card } from "../../CardGrid/types";
import { Partner } from "../../PartnerCarousel/PartnerCarousel";
import useElementEvents from "@/hooks/eventing/useElementEvents";
import { ElementClicked } from "@/hooks/eventing/types/redventures.usertracking.v3.ElementClicked";
import { useInView } from "react-intersection-observer";
import { HTMLAttributeAnchorTarget, useEffect } from "react";
import { hyphenateForTagular } from "@/utils/tagular/helpers";

type Props = {
  item: Card | Partner;
  index?: number;
  html_id?: string;
  location?: string;
  class_name?: string;
  children?: React.ReactNode;
  element_type?:
    | ElementType.Filter
    | ElementType.Entry
    | ElementType.Link
    | ElementType.Button;
  target?: HTMLAttributeAnchorTarget;
};

export default function LinkWithChildren({
  item,
  index = 0,
  html_id = "",
  location = "",
  class_name = "",
  children,
  element_type = ElementType.Entry,
  target = "",
}: Props) {
  const url = item?.href;

  const webElement: ElementClicked["webElement"] = {
    elementType: element_type ? element_type : ElementType.Entry,
    htmlId: html_id,
    location: location,
    name: hyphenateForTagular(item?.altText),
    position: (index + 1).toString(),
    text: item?.altText,
  };

  const { elementClicked, elementViewed } = useElementEvents({
    webElement,
  });

  const { inView, ref } = useInView(DEFAULT_USE_IN_VIEW_OPTIONS);

  useEffect(() => {
    if (inView) {
      elementViewed();
    }
  }, [inView, elementViewed]);

  const handleClick = () => {
    elementClicked({ outboundUrl: url });
  };

  return (
    <a
      ref={ref}
      className={class_name}
      href={url}
      onClick={handleClick}
      target={target}
    >
      {children}
    </a>
  );
}
