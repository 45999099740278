"use client";
import React from "react";
import { LinkListProps } from "../types";
import ContentColumnLinks from "./ContentColumnLinks";

export default function ContentColumns({ title }: LinkListProps) {
  // Used in Course Landing Shop Page "explore"
  const contentColumnsData = {
    title: title,
    columns: [
      {
        subtitle: "Earn a degree",
        description:
          "Take your learning to the next level with our comprehensive range of online degree programs.",
        links: [
          {
            className: "text-link underline",
            href: "https://www.edx.org/masters",
            text: "Online master's degrees",
          },
          {
            className: "text-link underline",
            href: "https://www.edx.org/bachelors",
            text: "Online bachelor's degrees",
          },
          {
            className: "text-link underline",
            href: "https://www.edx.org/doctorate",
            text: "Online doctorate degrees",
          },
          {
            className: "text-link underline",
            href: "https://www.edx.org/masters/micromasters",
            text: "MicroMasters® Programs",
          },
          {
            className: "text-link underline",
            href: "https://www.edx.org/bachelors/microbachelors",
            text: "MicroBachelors® Programs",
          },
        ],
      },
      {
        subtitle: "Gain a credential",
        description:
          "Looking for a flexible option? Explore free courses and certificates to get started instantly.",
        links: [
          {
            className: "text-link underline",
            href: "https://www.edx.org/",
            text: "Online courses",
          },
          {
            className: "text-link underline",
            href: "https://www.edx.org/certificates",
            text: "Online certificates",
          },
        ],
      },
      {
        subtitle: "Elevate your career",
        description:
          "Take your expertise to the next level with executive education programs.",
        links: [
          {
            className: "text-link underline",
            href: "https://www.edx.org/executive-education",
            text: "Online executive education programs",
          },
        ],
      },
    ],
  };

  return (
    <>
      <h3>{contentColumnsData.title}</h3>
      <div className="grid grid-cols-1 lg:grid-cols-3">
        {contentColumnsData.columns.map((column, index) => (
          <ContentColumnLinks key={index} {...column} />
        ))}
      </div>
    </>
  );
}
